import React from 'react';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import ContactForm from '../../components/PageFragments/ContactForm';
import SEO from '../../components/Seo';

const Contact = () => (
  <Layout className="outerPadding">
    <Layout className="container">
      <SEO
        title="Contact"
        description=""
        path="/contact"
        keywords={['']}
      />
      <Header />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Contact</h1>
        </div>
        <Row gutter={[40, 20]}>

          <Col sm={16} md={16} lg={24}>
            <h3>For all enquiries, please <a href="mailto:trelyntan17@gmail.com">email</a> me.</h3>
            <h3>Direct transfers, PayNow and PayLah are also available for bulk purchases. <a href="mailto:trelyntan17@gmail.com">Email</a> for enquiries</h3>
          </Col>
          
        </Row>
      </SidebarWrapper>
    </Layout>
  </Layout>
);

export default Contact;
